import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Hero from './components/Hero';
import WhyErda from './components/WhyErda';
import ContactUs from './components/ContactUs';
import Sidebar from './components/Sidebar';
import Footer from './components/Footer';
import OurTeam from './components/OurTeam';
import LearnMore from './components/LearnMore';
import Demo from './components/Demo'; // Import the Demo component
import './App.css';

const App = () => {
  const [scrolled, setScrolled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 100);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Router>
      <div className="App">
        <Header scrolled={scrolled} toggle={toggleSidebar} isOpen={isOpen} />
        <Sidebar isOpen={isOpen} toggle={toggleSidebar} />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Hero />
                <Demo /> {/* Add the Demo component here */}
                <WhyErda />
                <ContactUs />
              </>
            }
          />
          <Route path="/why-erda" element={<WhyErda />} />
          <Route path="/team" element={<OurTeam />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/learn-more" element={<LearnMore />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
};

export default App;