import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer>
      <div className="footer-content">
        <p>&copy; 2024 Sibel. All rights reserved.</p>
        <div className="social-media">
        <a href="https://x.com/SibelTechnology">X</a>
        <a href="https://www.linkedin.com/company/104631970/admin/dashboard/">LinkedIn</a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;