import React from 'react';
import './ContactUs.css';

const ContactUs = () => {
  return (
    <section className="contact-us">
      <h2>Contact us</h2>
      <form>
        <div className="form-group">
          <div className="form-left">
            <div className="form-left-item">
                <label>Email:</label>
                <input type="email" placeholder="johnny@appleseed.com" />
            </div>
            <div className="form-left-item">
                <label>Company Name:</label>
                <input type="text" />
            </div>
          </div>
          <div className="form-right">
            <label>Message:</label>
            <textarea placeholder="How could Sibel be used implemented in our organisation"></textarea>
          </div>
        </div>
        <button type="submit">Submit</button>
      </form>
    </section>
  );
};

export default ContactUs;