import React from 'react';
import { Link } from 'react-router-dom';
import { FaTimes } from 'react-icons/fa';
import './Sidebar.css';

const Sidebar = ({ isOpen, toggle }) => {
  return (
    <div className={`sidebar ${isOpen ? 'open' : ''}`}>
      <div className="sidebar-content">
        <div className="sidebar-header">
          <FaTimes className="close-icon" onClick={toggle} />
          <h2>Menu</h2>
        </div>
        <ul className="sidebar-menu">
          <li><Link to="/" onClick={toggle}>Main Page</Link></li>
          <li><Link to="/team" onClick={toggle}>Our Team</Link></li>
          <li><Link to="/learn-more" onClick={toggle}>Learn More</Link></li>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
