import React from 'react';
import './Hero.css';

const Hero = () => {
  return (
    <section className="hero">
      <div className="hero-logo-container">
        <img src={`${process.env.PUBLIC_URL}/images/sibel-logo-l.png`} alt="Sibel Logo" className="hero-logo" />
      </div>
      <h1>Sibel</h1>
      <p>Intelligent Solutions for Clear Communication</p>
    </section>
  );
};

export default Hero;