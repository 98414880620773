import React from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import './Header.css';

const Header = ({ scrolled, toggle, isOpen }) => {
  return (
    <div className="sibelHeader">
      <header className={scrolled ? 'scrolled' : ''}>
        <div className="menu-container">
          {isOpen ? (
            <FaTimes className="menu-icon" onClick={toggle} />
          ) : (
            <FaBars className="menu-icon" onClick={toggle} />
          )}
        </div>
        <div className="spacer" />
        <div className="logo-container">
          <Link to="/" className="logo-link">
            <div className="header-logo-container">
              <img src={`${process.env.PUBLIC_URL}/images/sibel-logo-l.png`} alt="Sibel Logo" className="logo" />
            </div>
          </Link>
        </div>
      </header>
    </div>
  );
};

export default Header;