import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import Popup from './Popup.js';
import Highlight from './Highlight.js';

import './Demo.css';

import flags from './flags/flags.js';

import financeData from './definitions/financeDefinitions';
import medicineData from './definitions/medicineDefinitions';
import lawData from './definitions/lawDefinitions';


const article_names = {
    'finance': 'Second Quarter 2024 Investment Review',
    'medicine': 'Heart Health',
    'law': 'Loper Bright Enterprises v. Raimondo'
};

const Demo = () => {
    const [selectedArticle, setSelectedArticle] = useState('finance');
    const [paragraphs, setParagraphs] = useState([]);
    const [selectedLang, setSelectedLang] = useState('gb');
    const [isHighlightsOn, setIsHighlightsOn] = useState(false);

    const [highlightedId, setHighlightedId] = useState(null);
    const [popupPos, setPopupPos] = useState({ top: 0, left: 0 });
    const [isTouchDevice, setIsTouchDevice] = useState(false);
    const [isPopupVisible, setIsPopupVisible] = useState(false);

    const [demoDelayHandler, setDemoDelayHandler] = useState(null);
    const [isDemoVisible, setIsDemoVisible] = useState(false);
    const [showDemo, setShowDemo] = useState(false);

    const articles = {
        finance: financeData,
        medicine: medicineData,
        law: lawData,
    };

    const translation = useMemo(() => {
        return articles[selectedArticle].translations[selectedLang]
    }, [selectedArticle, selectedLang]);
    const highlights = translation.highlights;
    const text = translation.translatedText;

    const createParagraphs = () => {
        const paras = [];

        var currIdx = 0;
        var endIdx = text.length;

        var id = 0;
        var word = highlights[id];

        do {
            const nextNewlineIdx = text.indexOf("\n", currIdx);
            const endOfLineIdx = nextNewlineIdx === -1 ? endIdx : nextNewlineIdx;

            let pElement = [];
            let pIdx = currIdx;

            while (word && word.endIdx < endOfLineIdx) {
                pElement.push(text.substring(pIdx, word.startIdx));

                if (isHighlightsOn) {
                    pElement.push(
                        <Highlight
                            key={ `mark-${id}` } 
                            id={ id }
                            word={ text.substring(word.startIdx, word.endIdx) }
                            setHighlightedId={ setHighlightedId }
                            setPopupPos={ setPopupPos }
                            setIsPopupVisible={ setIsPopupVisible }
                            isTouchDevice={ isTouchDevice }
                        />
                    );
                } else {
                    pElement.push(text.substring(word.startIdx, word.endIdx));
                }

                pIdx = word.endIdx;
                id += 1;
                word = highlights[id];
            }

            pElement.push(text.substring(pIdx, endOfLineIdx));
            paras.push(<p key={ `paragraph-${ paras.length }` }>{ pElement }</p>);

            currIdx = endOfLineIdx + 1;
        } while (currIdx < endIdx);

        setParagraphs(paras);
    };

    useEffect(() => {
        createParagraphs();
    }, [selectedArticle, selectedLang, isHighlightsOn]);

    const changeDemoVisibility = (shouldHide) => {
        if (!showDemo) {
            setShowDemo(true);
            setTimeout(() => setIsDemoVisible(true), 0);
            return;
        }

        if (shouldHide) {
            setIsDemoVisible(true); 
        } else {
            setIsDemoVisible(false);
        }
    }

    useEffect(() => {
        setIsTouchDevice('ontouchstart' in window);
    }, []);

    const handleArticleChange = (newArticle) => {
        setIsPopupVisible(false);
        setHighlightedId(null);
        setIsHighlightsOn(false);
        setSelectedArticle(newArticle);
    };

    const ArticleButton = ({
        subject,
    }) => {
        return (
            <button
                className={ `article-button ${selectedArticle === subject ? 'selected' : ''}` }
                onClick={() => handleArticleChange(subject)}
            >
                { subject.charAt(0).toUpperCase() + subject.slice(1) }
            </button>
        );
    }

    const LangButton = ({
        lang,
    }) => {
        return (
            <button 
                className={ selectedLang === lang ? "selected" : "" }
                onClick={() => setSelectedLang(lang)}
            >
                <img
                    className="flag"
                    src={ flags[lang] }
                    alt={ lang }
                />
            </button>
        );
    }

    return (
        <div className="demo-container">
            <div className="demo-buttons">
                <button className="try-it-button" onClick={() => changeDemoVisibility(!isDemoVisible)}>Try it!</button>
                <p className="see-work-text">See Sibel’s work in action</p>
                {showDemo && (
                    <div className={"article-buttons demo-" + (isDemoVisible ? "fade-in" : "fade-out")}>
                        <ArticleButton subject="finance" />
                        <ArticleButton subject="medicine" />
                        <ArticleButton subject="law" />
                    </div>
                )}
            </div>
            {showDemo && (
                <div className={"demo demo-" + (isDemoVisible ? "fade-in" : "fade-out")}>
                    <header>
                        <div className="headerGroup">
                            <div className="logo">
                                &#10166;
                            </div>
                        </div>
                        <div className="hederGroup">
                            <LangButton lang="gb" />
                            <LangButton lang="se" />
                        </div>
                    </header>

                    <main id="offsetParent">
                        <h2>{article_names[selectedArticle]}</h2>
                        <button className="highlight-button" onClick={() => { setIsHighlightsOn(true) }}>Highlight</button>
                        <div className="textContainer" id="textContainer">
                            { paragraphs }
                        </div>
                        { highlightedId !== null && (
                            <Popup 
                                id={ highlightedId }
                                setId={ setHighlightedId }
                                isVisible={ isPopupVisible }
                                setIsVisible={ setIsPopupVisible }
                                position={ popupPos }
                                title={ 
                                    text.substring(
                                        highlights[highlightedId].startIdx,
                                        highlights[highlightedId].endIdx
                                    ).charAt(0).toUpperCase() +
                                    text.substring(
                                        highlights[highlightedId].startIdx,
                                        highlights[highlightedId].endIdx
                                    ).slice(1)
                                }
                                definition={ highlights[highlightedId].definition }
                                simplified={ highlights[highlightedId].simplified }
                                example={ highlights[highlightedId].example }
                            />
                        )}
                    </main>
                </div>
            )}
        </div>
    );
};

export default Demo; 
